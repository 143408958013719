import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert, Button } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#C0573B'
let black = 'rgb(38,38,38)'

let id_temp = 'feby-tunggul'
let id = 'muthya-jonathan'
let inisial_co = 'Adi'
let inisial_ce = 'Muthya'
let lengkap_co = 'Jonathan Adi Biran Munandir, S.H., M.Kn'
let lengkap_ce = 'Muthya Shinta Devi, dr.'
let bapak_co = 'Bpk. Mulyadi'
let ibu_co = 'Ibu Tuti Herawati (almh.)'
let bapak_ce = "Bpk. Nofriadi "
let ibu_ce = "Ibu Detri Yusziani"
let ig_co = "munandir"
let ig_ce = "muthymumu"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "02/13/2021"

let modal = pw(id, "Muthya.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/RMqXSiQAMctZun3DA"
let gcalendar = `https://calendar.google.com/event?action=TEMPLATE&tmeid=MG1zNmh0YTJ1NzJucmtnbDUyOTE0dGdiZWMgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com`
let gmaps1 = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.153841908944!2d112.71299191477523!3d-7.3366140947054275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fba9cfb6dfab%3A0x6fe7210ef241206!2sMasjid%20Nasional%20Al-Akbar%20Surabaya!5e0!3m2!1sid!2sid!4v1612315308760!5m2!1sid!2sid" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`

gmaps1 = gmaps1.split('"')[1]


export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0
        }
    }

    componentDidMount() {
        AOS.init({

            duration: 2000
        });
        let s = this.useQuery().get('s');
        if (s == "zxc") {
            this.setState({ sesi: 1 })
        } else if (s == "cxz") {
            this.setState({ sesi: 2 })
        }

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi } = this.state
        let slider = []
        let slide = [
            "IMG_4358.jpg",
            "IMG_4359.jpg",
            "IMG_4378-1.jpg",
            "IMG_4379.jpg",
            "IMG_4380.jpg",
        ]
        let story = [
            "	https://i.ibb.co/T2qKtDH/2.jpg	",
            "	https://i.ibb.co/Nr6J8VK/3.jpg	",
            "	https://i.ibb.co/1TJCDxD/4.jpg	",
            "	https://i.ibb.co/6wzHBHw/5.jpg	",
            "	https://i.ibb.co/kJyJZz2/1.jpg	",

        ]
        let caption = [
            <>
                Melalui tangan-tangan orang terkasih, kita saling tahu tanpa mengenal satu dan lainnya<br />
                Orang-orang menjadi pandai membaca garis Tuhan sehingga yang diyakini saat itu adalah kita sedang asyik menjaga buku perjodohan masing-masing
            </>,
            <>
                Tapi versi Yang Kuasa tentu lain, persoalan yang tadinya milik sendiri-sendiri, perlahan justru berpilin saling menemukan<br />
Ada kesan khusus terhadap jalan yang dikehendaki-Nya, "senantiasa menakjubkan" karena tidak cukup hanya dikata sebuah kebetulan.

            </>
            ,
            <>
                Ada keyakinan tersendiri ketika memilih setuju terhadap sesuatu yang tadinya dalam keragu-raguan<br />
Tapi begitulah tangan Tuhan bermain menggerakkan hati hamba-hamba-Nya

            </>, <>
                Setelah ini, segala harapan akan dituai, di jalan berikutnya semoga mampu saling menjaga hingga larut usia kita<br />
            </>
        ]
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undang.in/${id}`}
                />

                <div id='gold5' style={{
                    backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <div style={{
                                position:'absolute',
                                height:'100vh',
                                width:'100vw',
                                top:0,
                                left:0,
                                backgroundColor:'rgba(0,0,0,0.5)' 
                                           }}>

                            </div>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kepada Yth :<br />
Bapak/Ibu/Saudara/i<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>

                                        <p className="fs16 text-center cblack px-3">
                                            “Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.” <br /><br />(Ar-Rum: 21)


                                        </p>
                                    </Item>

                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>

                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            The Intimate Wedding of <br /> {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '24px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putri dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '24px',
                                                        fontFamily: "'Marck Script', cursive", color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putra dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                                    <Item>
                                        <p className="fs16">
                                            Yang Insyaa Allah akan dilaksanakan pada:
                                        </p>
                                    </Item>

                                    <Item>
                                        <Col md={12} className="mx-1">
                                            <Item>
                                            <p style={{ fontSize: '20px', color: cmain }} className="w-100 text-center">
                                                <b>Akad Nikah </b><br />
                                                <div style={{ fontSize: '16px' }}>
                                                    (Hanya dihadiri oleh Keluarga)
                                                    </div>
                                            </p>
                                            <p style={{ fontSize: '16px', color: 'black' }} className="w-100 text-center">
                                                <b>
                                                    Jumat, 12 Februari 2021
                                                </b><br />
                                                08.00 WIB - Selesai
                                            </p>
                                            <p style={{ fontSize: '16px', color: 'black' }} className="w-100 text-center">
                                                <b>
                                                Rumah Mempelai Wanita

                                                </b><br />
                                                Jl. Karah Tama No.53 Surabaya
                                            </p>
                                            </Item>
                                            <Item>
                                                <p style={{ fontSize: '16px' }}>
                                                    Live Streaming :
                                                </p>
                                            </Item>
                                            <Item>
                                                <Button style={{ backgroundColor: 'rgb(42, 78, 53)' }}
                                                    className=""
                                                    onClick={() => {
                                                        window.location.href = 'https://cutt.ly/TheweddingofMuthyaAdi'
                                                    }}>
                                                    <Item>
                                                        <img src="https://i.pinimg.com/originals/de/1c/91/de1c91788be0d791135736995109272a.png" className="img-fluid"
                                                            style={{ width: "10%", height: '10%' }} /> Youtube
                                                    </Item>
                                                </Button>
                                            </Item>
                                            <Item>
                                                <Button style={{ backgroundColor: 'rgb(42, 78, 53)' }}
                                                    className="mt-3"
                                                    onClick={() => {
                                                        window.location.href = 'https://instagram.com/muthymumu '
                                                    }}>
                                                    <Item>
                                                        <img src={logoig} className="img-fluid"
                                                            style={{ width: "10%", height: '10%' }} /> Instagram
                                                    </Item>
                                                </Button>
                                            </Item>

                                        </Col>
                                        <Col md={12} className="mx-1 mt-3">
                                            

                                            <p style={{ fontSize: '20px', color: cmain }} className="w-100 text-center">
                                                <b>Resepsi</b>
                                            </p>
                                            <p style={{ fontSize: '16px', color: 'black' }} className="w-100 text-center">
                                                <b>
                                                    Sabtu, 13 Februari 2021
                                                </b><br />
                                                {sesi == 1 ? "09.00 - 11.00 WIB" : ""}
                                                {sesi == 2 ? "11.00 - 13.00 WIB" : ""}
                                            </p>

                                        </Col>
                                        <p className="w-100 textcenter" style={{ color: cmain, fontSize: '16px' }}>
                                            <b>Gedung Al-Marwah Masjid Nasional Al-Akbar Surabaya</b>
                                            <br />
                                            Jl. Mesjid Agung Tim. No.1, Pagesangan, Kec. Jambangan, Kota SBY, Jawa Timur 60233
                                        </p>


                                    </Item>

                                    <Item>
                                        <p style={{ fontSize: '16px', fontStyle: 'italic' }}>Undangan berlaku untuk 2 orang</p>
                                    </Item>
                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />

                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={covid} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                            From this day forward,<br />
You shall not walk alone.<br />
My heart will be your shelter,<br />
And my arms will be your home.
</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>
                                <Container className="py-5 px-md-5 col-md-8 px-3"
                                    style={{ color: cmain, fontFamily: 'Patrick Hand, cursive' }}
                                >

                                    <Row className="position-relative px-5 py-5" style={{ backgroundColor: 'rgba(255,255,255,0.1)' }}>
                                        <h1 className="peri w-100 text-center">
                                            Our Love Story
                  </h1>
                                        <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', top: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', top: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="mirror img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', bottom: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="flip180-m img-fluid w-100" />
                                        </Col>
                                        <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', bottom: 0 }}>
                                            <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className=" flip180  img-fluid w-100" />
                                        </Col>
                                        {story.map((v, i) => {
                                            return (
                                                <Item>

                                                    <Row className="h-100">
                                                        <p className="w-100 text-center my-auto" style={{ fontSize: '18px' }}>
                                                            {caption[i]}
                            </p>
                                                    </Row>

                                                </Item>
                                            )
                                        })}
                                    </Row>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: 'Tinos',
                                                        color: cmain
                                                    }}>
                                                        <span style={{ fontSize: '18px' }}>
                                                            Ucapan untuk</span> <br />
                                                        <span style={{ fontFamily: 'Alex Brush, cursive' }}>Muthya & Adi</span>
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Nama" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Ucapan" name='pesan' />
                                                        <Item>
                                                            <div onClick={() => {
                                                                this.setState({ hadir: true })
                                                            }
                                                            }>
                                                                <input id="option" type="radio" checked={hadir ? true : false} />
                                                                <label ><span><span></span></span>Hadir</label>
                                                            </div>
                                                            <div onClick={() => {
                                                                this.setState({ hadir: false })
                                                            }
                                                            }>
                                                                <input id="option" type="radio" checked={hadir ? false : true} />
                                                                <label ><span><span></span></span>Berhalangan Hadir</label>
                                                            </div>
                                                        </Item>
                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Foot ig={logoig} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

